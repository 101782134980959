module.exports={
    "animation": {
        "autoplay": true
    },
    "apiUrlBase": "https://api.jthelander.com/complander",
    "apiVersion": "",
    "assetsUrlBase": "https://complander.com/20240315_2125_7a7795b6d61295face609794e493cceed1f92f50/mothership/assets",
    "blurString": "$◼︎◼︎◼︎",
    "cdnUrlBase": "https://complander.com/20240315_2125_7a7795b6d61295face609794e493cceed1f92f50",
    "mobileWidth": 767,
    "mockdata": {
    },
    "showApiHint": false
}
